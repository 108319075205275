import loadable from '@loadable/component';
import React, { useCallback, useEffect, useState } from 'react';

const HomePageEntitiesListScrollUpShown = loadable(() => import(
  /* webpackChunkName: "page-home-helper-scroll-up-shown" */
  './HomePageEntitiesListScrollUpShown'
));

const SCROLL_LIMIT = 500;

function helperAttach(onScroll: () => void): void {
  const listElement = document.querySelector('.app-entities-list');

  listElement?.addEventListener('scroll', onScroll, { passive: true });
  window.addEventListener('scroll', onScroll, { passive: true });
}

function helperCleanup(onScroll: () => void): void {
  const listElement = document.querySelector('.app-entities-list');

  listElement?.removeEventListener('scroll', onScroll);
  window.removeEventListener('scroll', onScroll);
}

// let renderCounter = 0;

const HomePageEntitiesListScrollUp: React.FC = () => {

  // console.debug(`Render HomePageEntitiesListScrollUp ${++renderCounter}`);

  const [show, setShow] = useState(false);

  const onScroll = useCallback(() => {
    const listElement = document.querySelector('.app-entities-list');
    const currentScroll = listElement?.scrollTop || document.body.scrollTop || document.documentElement.scrollTop || 0;

    setShow(currentScroll > SCROLL_LIMIT);
  }, []);

  useEffect(() => {
    helperCleanup(onScroll);
    helperAttach(onScroll);

    return () => {
      helperCleanup(onScroll);
    };
  }, [onScroll]);

  return show
    ? <HomePageEntitiesListScrollUpShown />
    : null;
};

export default HomePageEntitiesListScrollUp;
