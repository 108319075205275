
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) {
    throw new Error('Array value cannot be empty or undefined');
  }

  return true;
}

function notEmptyAllow<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined || value === false) {
    return false;
  }

  return true;
}

class ArrayHelper {
  public static unique<T>(array: T[]): T[] {
    return array.filter((value: T, index: number, self: T[]) => {
      return self.indexOf(value) === index;
    });
  }

  public static filter<T>(array: (T | undefined)[]): T[] {
    return array.filter(notEmpty);
  }

  public static filterAllowEmpty<T>(array: (T | undefined)[]): T[] {
    return array.filter(notEmptyAllow);
  }
}

export default ArrayHelper;
